<template>
  <div class="billList">
    <!-- 账单页 -->
    <div v-if="list.length > 0">
        <div  v-for="item in list" :key="item.id" class="df jcc">
          <div class="bg-white item-box">
            <div class="flex jc-sb alc">
            <div class="item-top flex">
              <span class="appName font-bold ">{{item.loanName}}</span>
              <span class="color-ee ">借款期数: {{ item.loanLimit
               }}月</span>
            </div>
            <span class="nowStatus " v-if="item.status == 'UNCURRENT'" >未到期</span
              >
            <span class="nowStatus " v-if="item.status == 'BACKING'" >待还款</span
              >
            <span class="nowStatus2 " v-if="item.status == 'OVERDUED'" >已逾期</span
              >
          </div>
          <div class="df jc-sb m-t-24">
            <div>
              <div class="font-bold font-58 money-box FZFW">{{ $filters.formatMoney(item.raiseAmt, 2) }}</div>
              <div class="color-ee ">借款金额(元)</div>
            </div>
            <div class="df fdc jc-sb">
              <div>
                <span class="color-ee ">还款方式</span>
                <span class="m-l-14 font-bold font-s-30">按月等本等息</span>
              </div>
              <div>
                <span class="color-ee ">放款日期</span>
                <span class="m-l-14 font-bold font-s-30">{{ item.gmtCreatedStr }}</span>
              </div>
            </div>
          </div>
          <div class="hr"></div>
          <div class="btns df alc jc-end">
            <div @click="withBtn(item)" class="btn-bill">代扣卡管理</div>
            <div @click="JumpLink(item)" class="m-l-14 btn-bill">去还款</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 无账单 -->
    <div class="noBilllist df jcc" v-else>
      <div class="nobill-content df jcc fdc alc">
        <img src="./img/bill-bgc.png" alt="" />
        <p>目前还没有账单哦</p>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from "vue";
import API from "../../server/api";
import { Jump } from '@/utils/routh';
export default {
  name: "Bill",
  setup() {
    // 定义当前页面的state
    const state = reactive({
      list: [],
      hasNextPage: false,
      desc: "目前还没有账单哦，去借一笔",
    });
    // 页面加载
    onMounted(() => {
      const loanData = localStorage.getItem('loanModelList')
      if(loanData){
        state.list = JSON.parse(loanData);
      }
      init()
    });

    // 初始化
    const init = async ()=>{
      try{
        const res = await API.isUser()
        if(res.success){
          const {loanModelList} = res.data;
          state.list = loanModelList
        }
      }catch (err){
        console.log(err.message)
      }
    }
    //立即还款
    const JumpLink = (item) => {
      Jump(`/billDetail`,{
        loanNo:item.loanNo
      });
    };
    const withBtn = (item) => { 
      localStorage.setItem('loanNos', item.loanNo)
      Jump('/Withhold', {loanNo: item.loanNo})
    }
    return {
      ...toRefs(state),
      JumpLink,
      withBtn,
      Jump
    };
  },
};
</script>

<style lang="less" scoped>

.item-box {
  width: 690px;
  background: #FFFFFF;
  box-shadow: 0px 8px 20px 0px #E1EBFF;
  border-radius: 12px;
  margin-top: 24px;
  padding: 29px 36px 30px 30px;
  box-sizing: border-box;
}
.appName {
  margin-right: 10px;
  font-size: 44px;
}
.m-t-24 {
  margin-top: 40px;
}
.money-box {
  margin-bottom: 16px;
}
.m-l-14 {
  margin-left: 14px;
}
.hr {
  width: 100%;
  height: 1px;
  background: #E1EBFF;
  margin: 26px 0;

}
.btn-bill {
  font-size: 30px;
  padding: 10px 20px;
  border: 2px solid #fd1b7d;
  color: #fd1b7d;
  border-radius: 100px;
}

.color-ee {
  color: #999;
  font-size: 28px;
}
.nowStatus {
  font-size: 28px;
  font-weight: 500;
  color: #3877f8;
}

.nowStatus1 {
  font-size: 28px;
  font-weight: 500;
  color: #3877f8;
}

.nowStatus2 {
  font-size: 28px;
  font-weight: 500;
  color: #fc4d3e;
}
.font-s-30 {
  font-size: 30px;
}
.font-s-24 {
  font-size: 24px;
}
</style>
